<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

                <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected" />
                <ProductDetailsMED v-else :product="selected" />

            </b-modal>


            <b-card>
                <b-row>
                    <b-col cols="12" md="5">
                        <b-tabs pills nav-class="nav-pill-PRIMARY">
                            <b-tab title="MEDICAMENTS" active @click="medClicked" />
                            <b-tab title="PARA-MEDICAMENTS" @click="paraClicked" />
                        </b-tabs>
                    </b-col>
                    <b-col cols="12" md="7">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                </b-row>
                <ag-grid :agGrid="agGrid" ref="agGrid" pageTitle="Liste des articles" @refresh="loadData"
                    @cellDoubleClicked="cellDoubleClicked" :autoSizeColumns="true" :showHeader="false" />

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import ProductDetailsMED from "./ProductDetailsMED.vue";
import ProductDetailsPARA from "./ProductDetailsPARA.vue";
import BLinkCellRenderer from "./BLinkCellRendererWithImage.vue";
import IconCellRenderer from "./IconCellRenderer.vue";

export default {
    components: {
        AgGrid,
        ProductDetailsMED,
        ProductDetailsPARA
    },
    data() {
        return {
            selected: {},
            searchQuery: "",
            MEDcolumnDefs: [
                {
                    headerName: "Description",
                    field: "description",
                    cellRendererFramework: 'bLinkCellRenderer',
                    cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                    width: 350
                },
                {
                    headerName: "DCI",
                    field: "dciDescription",
                },
                {
                    headerName: "Code PCT",
                    field: "pct",
                },
                {
                    headerName: "Barcode",
                    field: "barCode",
                },
                {
                    headerName: "Cartonnage",
                    field: "packaging",
                },
                {
                    headerName: "TVA",
                    field: "vat",
                },
                {
                    headerName: "Forme",
                    field: "form",
                },
                {
                    headerName: "Présentation",
                    field: "presentation",
                },
                {
                    headerName: "Conditionnement primaire",
                    field: "primaryPackaging",
                },
                {
                    headerName: "Spécification",
                    field: "specificationPrimaryPackaging",
                },
                {
                    headerName: "Classement VEIC",
                    field: "veic",
                },
                {
                    headerName: "Classe Thérapeutique",
                    field: "_class",
                },
                {
                    headerName: "Sous Classe",
                    field: "veic",
                },
                {
                    headerName: "Classement VEIC",
                    field: "subClass",
                },
                {
                    headerName: "Laboratoire",
                    field: "vendorDescription",
                },
                {
                    headerName: "Générique/Princeps",
                    field: "category",
                },
                {
                    headerName: "Tableau",
                    field: "_table",
                },
                {
                    headerName: "Tableau",
                    field: "_table",
                },
                {
                    headerName: "CNAM",
                    field: "apcnam",
                    cellRendererFramework: 'iconCellRenderer',
                    width: 100
                },
            ],
            PARAcolumnDefs: [
                {
                    headerName: "Description",
                    field: "description",
                    cellRendererFramework: 'bLinkCellRenderer',
                    cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                    width: 350
                },
                {
                    headerName: "Fournisseur",
                    field: "vendorDescription",
                },
                {
                    headerName: "Famille",
                    field: "familyDescription",
                },
                {
                    headerName: "Sous-Famille",
                    field: "subFamilyDescription",
                },
                {
                    headerName: "Barcode",
                    field: "barCode",
                },
                {
                    headerName: "Cartonnage",
                    field: "packaging",
                },
                {
                    headerName: "TVA",
                    field: "vat",
                },
            ],
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.cellDoubleClicked, },
                        width: 350
                    },
                    {
                        headerName: "DCI",
                        field: "dciDescription",
                    },
                    {
                        headerName: "Code PCT",
                        field: "pct",
                    },
                    {
                        headerName: "Barcode",
                        field: "barCode",
                    },
                    {
                        headerName: "Cartonnage",
                        field: "packaging",
                    },
                    {
                        headerName: "TVA",
                        field: "vat",
                    },
                    {
                        headerName: "Forme",
                        field: "form",
                    },
                    {
                        headerName: "Présentation",
                        field: "presentation",
                    },
                    {
                        headerName: "Conditionnement primaire",
                        field: "primaryPackaging",
                    },
                    {
                        headerName: "Spécification",
                        field: "specificationPrimaryPackaging",
                    },
                    {
                        headerName: "Classement VEIC",
                        field: "veic",
                    },
                    {
                        headerName: "Classe Thérapeutique",
                        field: "_class",
                    },
                    {
                        headerName: "Sous Classe",
                        field: "veic",
                    },
                    {
                        headerName: "Classement VEIC",
                        field: "subClass",
                    },
                    {
                        headerName: "Laboratoire",
                        field: "vendorDescription",
                    },
                    {
                        headerName: "Générique/Princeps",
                        field: "category",
                    },
                    {
                        headerName: "Tableau",
                        field: "_table",
                    },
                    {
                        headerName: "Tableau",
                        field: "_table",
                    },
                    {
                        headerName: "CNAM",
                        field: "apcnam",
                        cellRendererFramework: 'iconCellRenderer',
                        width: 100
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
        tableData() {
            return this.$store.getters["app-items/geItemsMED"];
        },
    },
    watch: {
        tableData(products) {
            if (products.length > 0) {
                this.showLoading = false;
                this.agGrid.rows = [...products]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
            }
        },
    },
    created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.iconCellRenderer = IconCellRenderer;
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        if (this.$store.getters["app-items/geItemsMED"].length > 0) {
            setTimeout(() => {
                this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
            }, 800);
        }
    },
    methods: {
        medClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            this.agGrid.columnDefs = this.MEDcolumnDefs;
            this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            this.showLoading = false;
        },
        paraClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            this.agGrid.columnDefs = this.PARAcolumnDefs;
            this.agGrid.rows = this.$store.getters["app-items/geItemsPARA"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            // setTimeout(() => {
            //     if (!/Mobi/i.test(navigator.userAgent))
            //         this.$refs.agGrid.gridApi.sizeColumnsToFit();
            // }, 200);
            this.showLoading = false;
        },
        async showDetails(data) {
            this.selected = data;
            this.$refs['item-modal'].show();
        },
        loadData() {
            this.showLoading = true;
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND');
        },
        updateSearchQuery(val) {
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.showDetails(param.data);
        }
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>